import { FC, HTMLAttributes, ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonIconPosition } from 'bloko/blocks/button';
import { TrashScaleSmall, PenSquareScaleSmall, CameraScaleSmall, ArrowsScaleSmallKindDefault } from 'bloko/blocks/icon';

import { employerConstructorRemoveWidget } from 'src/models/employerConstructor';

interface WidgetControlsProps {
    drag?: boolean;
    upload?: boolean;
    uploadOverride?: ReactNode;
    uploadText?: string;
    edit?: boolean;
    editText: string;
    editOverride?: ReactNode;
    remove?: boolean;
    onUpload?: () => void;
    onEdit: () => void;
    id: number;
    name: string;
    getMovedElementProps: () => Pick<HTMLAttributes<HTMLDivElement>, 'onMouseDown' | 'onTouchStart'>;
}

const WidgetControls: FC<WidgetControlsProps> = ({
    drag = true,
    upload = true,
    uploadOverride,
    uploadText,
    edit = true,
    editText,
    editOverride,
    remove = true,
    onUpload,
    onEdit,
    id,
    name,
    getMovedElementProps,
}) => {
    const dispatch = useDispatch();
    const onRemove = useCallback(() => {
        dispatch(employerConstructorRemoveWidget(id));
    }, [dispatch, id]);

    return (
        <div className="widget-controls">
            <div>
                {drag && (
                    <div className="widget-controls__move-wrapper" {...getMovedElementProps?.()}>
                        <div className="widget-controls__move-overlay">
                            <Button icon={<ArrowsScaleSmallKindDefault />} />
                        </div>
                    </div>
                )}
                <span className="widget-controls__name">{name}</span>
            </div>
            <div>
                {upload && uploadOverride}
                {upload && !uploadOverride && (
                    <div className="widget-controls__button-wrapper">
                        <Button onClick={onUpload} icon={<CameraScaleSmall />} iconPosition={ButtonIconPosition.Left}>
                            {uploadText}
                        </Button>
                    </div>
                )}
                {edit && editOverride}
                {edit && !editOverride && (
                    <div className="widget-controls__button-wrapper">
                        <Button onClick={onEdit} icon={<PenSquareScaleSmall />} iconPosition={ButtonIconPosition.Left}>
                            {editText}
                        </Button>
                    </div>
                )}
                {remove && (
                    <div className="widget-controls__button-wrapper">
                        <Button onClick={onRemove} icon={<TrashScaleSmall />} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default WidgetControls;
