import { useMemo } from 'react';

import { useSelector } from 'src/hooks/useSelector';

const getGalleryMaxItemsCount = (state) => state.employerConstructorSettings.widgetSettings.galleryMaxItemsCount;
const getPictureSettings = (state) => state.employerConstructorSettings.pictureSettings.GALLERY_WIDGET;

const useSettings = () => {
    const pictureSettings = useSelector(getPictureSettings);
    const galleryMaxItemsCount = useSelector(getGalleryMaxItemsCount);

    const widgetSettings = useMemo(
        () => ({
            maxCountPictures: galleryMaxItemsCount,
        }),
        [galleryMaxItemsCount]
    );

    return [pictureSettings, widgetSettings];
};

export default useSettings;
