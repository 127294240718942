import { forwardRef, ForwardRefRenderFunction } from 'react';
import classnames from 'classnames';

import ErrorComponent from 'src/components/EmployerConstructor/ErrorComponent';
import { useSelector } from 'src/hooks/useSelector';

interface WidgetWrapperProps {
    edit: () => void;
    view: () => void;
    editMode: boolean;
    viewReady: boolean;
    dragged: boolean;
    invalid?: string;
}

const WidgetWrapper: ForwardRefRenderFunction<HTMLDivElement, WidgetWrapperProps> = (
    { edit, view, editMode, viewReady, dragged, invalid },
    ref
) => {
    const webViewMode = useSelector(({ employerConstructorWebViewMode }) => employerConstructorWebViewMode);

    if (!viewReady && !editMode) {
        return null;
    }
    return (
        <>
            <div
                className={classnames('employer-constructor-widget', {
                    'employer-constructor-widget_view-mode': !editMode,
                    'employer-constructor-widget_webview': webViewMode,
                    'employer-constructor-widget_dragged': dragged,
                })}
                ref={ref}
            >
                <>
                    {editMode && edit()}
                    {viewReady && !editMode && view()}
                </>
            </div>
            <ErrorComponent show={!!invalid}>{invalid}</ErrorComponent>
        </>
    );
};

export default forwardRef(WidgetWrapper);
