import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import {
    employerConstructorEditForbiddenError,
    employerConstructorInvalidPictureSize,
    employerConstructorPictureFileTooLarge,
    employerConstructorPictureTooLarge,
    employerConstructorUnsupportedPictureFileFormat,
} from 'src/components/Notifications/EmployerConstructor';
import { employerConstructorAddImage, Status, switchImagesActionWrapper } from 'src/models/employerConstructor';
import fetcher from 'src/utils/fetcher';

import checkResizeStatus, { CHECK_TIMEOUT_MS } from 'src/components/EmployerConstructor/checkResizeStatus';

const uploadImage =
    ({ file = {}, pictureType, widgetId }, addNotification) =>
    (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const imagesConfig = getState().employerConstructorSettings.pictureSettings[pictureType];
            if (!imagesConfig.allowedMimeTypes.includes(file.type)) {
                addNotification(employerConstructorUnsupportedPictureFileFormat);
                reject();
                return;
            }
            if (file.size > imagesConfig.maxSizeBytes) {
                addNotification(employerConstructorPictureFileTooLarge);
                reject();
                return;
            }
            dispatch(
                switchImagesActionWrapper({
                    payload: {
                        resizeStatus: Status.Fetching,
                    },
                    widgetId,
                })
            );

            fetcher.postFormData('/employer/constructor/upload_image', { file, pictureType }).then(
                ({ data }) => {
                    dispatch(employerConstructorAddImage(data));
                    setTimeout(() => {
                        dispatch(
                            checkResizeStatus(
                                { pictureId: data.pictureId, widgetId },
                                { fromUpload: true },
                                addNotification
                            )
                        )
                            .then(() => resolve({ pictureId: data.pictureId }))
                            .catch(() => reject());
                    }, CHECK_TIMEOUT_MS);
                },
                (error) => {
                    dispatch(switchImagesActionWrapper({ payload: { resizeStatus: Status.Fail }, widgetId }));
                    if (error?.response?.status === 403 && error?.response?.data.error) {
                        addNotification(employerConstructorEditForbiddenError);
                    } else {
                        switch (error.response?.data?.error?.[0]?.key) {
                            case 'PICTURE_FILE_TOO_LARGE':
                                addNotification(employerConstructorPictureFileTooLarge);
                                break;
                            case 'PICTURE_TOO_LARGE':
                                addNotification(employerConstructorPictureTooLarge, {
                                    props: {
                                        maxWidth: imagesConfig.maxWidth,
                                        maxHeight: imagesConfig.maxHeight,
                                    },
                                });
                                break;
                            case 'BAD_PICTURE_FILE':
                            case 'UNSUPPORTED_PICTURE_FILE_FORMAT':
                                addNotification(employerConstructorUnsupportedPictureFileFormat);
                                break;
                            case 'INVALID_PICTURE_SIZE':
                                addNotification(employerConstructorInvalidPictureSize, {
                                    props: {
                                        minimumWidth: imagesConfig.minWidth,
                                        minimumHeight: imagesConfig.minHeight,
                                    },
                                });
                                break;
                            default:
                                defaultRequestErrorHandler(error, addNotification);
                        }
                    }
                    reject();
                }
            );
        });
    };

export default uploadImage;
