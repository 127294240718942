import { FC, useEffect, useState } from 'react';

import { useBreakpoint } from '@hh.ru/magritte-ui';

import { EmployerAddressShort, prepareNewAddress } from 'Modules/EmployerAddresses/helpers';
import { AddressFromYandexData } from 'Modules/Maps/Utils';
import useIsBackoffice from 'src/hooks/useIsBackoffice';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerAddress } from 'src/models/employerAddresses.types';
import fetcher from 'src/utils/fetcher';

import AddAddressModalContent from 'src/components/AddressSuggest/AddAddressModalContent';
import { AddressDuplicateType } from 'src/components/AddressSuggest/types';

declare global {
    interface FetcherGetApi {
        // строковый шаблон `/employer/addresses/${number}` не работает, поэтому так
        employerAddressById: {
            queryParams: Record<string, never>;
            response: { addresses: EmployerAddress[] };
        };
    }
}

export interface AddAddressModalProps {
    visible?: boolean;
    addressId?: number;
    duplicate?: AddressDuplicateType;
    onClose: () => void;
    onCreate: (address: EmployerAddressShort) => void;
    onSelect: (address: EmployerAddress) => void;
    onUpdate?: (address: EmployerAddressShort) => void;
    onDeleteClicked?: (addressId?: string) => void;
}

const AddAddressModal: FC<AddAddressModalProps> = ({
    onCreate,
    onSelect,
    onUpdate,
    onDeleteClicked,
    visible,
    onClose,
    addressId,
    duplicate = 'use',
}) => {
    const { isMobile } = useBreakpoint();
    const [addressModalKey, setAddressModalKey] = useState<number | null>(null);
    const addresses = useSelector((state) => state.employerAddresses);
    const isBackoffice = useIsBackoffice();
    const employerManagerId = useSelector((state) =>
        isBackoffice ? state.employerManagersList.find((manager) => manager.isMCP)?.id : undefined
    );

    const onAddressCreate = (addressObj: AddressFromYandexData) => {
        const preparedAddress = prepareNewAddress(addressObj);
        onCreate(preparedAddress);
    };

    const onAddressSelect = async ({ id }: { id?: number }) => {
        const addressItem = addresses.find((address) => address.id === id);
        if (addressItem) {
            onSelect(addressItem);
            return;
        }

        if (id) {
            const response = await fetcher.get(`/employer/addresses/${id}` as 'employerAddressById');
            const addressItem = response?.addresses?.[0];
            if (addressItem) {
                onSelect(addressItem);
            }
        }
    };

    const onAddressUpdate = (addressObj: AddressFromYandexData) => {
        if (onUpdate) {
            const preparedAddress = prepareNewAddress(addressObj);
            onUpdate(preparedAddress);
        }
    };

    const onAddressDelete = ({ id }: { id?: string }) => {
        if (onDeleteClicked) {
            onDeleteClicked(id);
        }
    };

    useEffect(() => {
        setAddressModalKey(new Date().valueOf());
    }, [isMobile]);

    return (
        <AddAddressModalContent
            key={addressModalKey}
            visible={visible}
            addressId={addressId}
            employerManagerId={employerManagerId}
            duplicate={duplicate}
            onModalClose={() => {
                onClose();
                setAddressModalKey(new Date().valueOf());
            }}
            onAddressSelect={onAddressSelect}
            onAddressUpdate={onAddressUpdate}
            onAddressCreate={onAddressCreate}
            onAddressDelete={onAddressDelete}
        />
    );
};

export default AddAddressModal;
