import { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Text, { TextSize } from 'bloko/blocks/text';
import { format } from 'bloko/common/trl';

import scrollToElement from 'Utils/ScrollToElement';
import TextContentEditor from 'src/components/Employer/TextContentEditor';
import WidgetTip from 'src/components/EmployerConstructor/WidgetTip';
import { textHasError } from 'src/components/EmployerConstructor/validate';
import RawUserContent from 'src/components/RawUserContent';
import translation from 'src/components/translation';
import { employerConstructorModifyWidget } from 'src/models/employerConstructor';
import Widget from 'src/models/employerConstructor/widget.types';

import WidgetControls from 'src/components/EmployerConstructor/widgets/WidgetControls';
import WidgetWrapper from 'src/components/EmployerConstructor/widgets/WidgetWrapper';

const TextWidget = ({
    trls,
    editMode,
    content,
    id,
    invalid,
    invalidOnSave,
    employerConstructorModifyWidget,
    getMovedElementProps,
    dragged,
    hasDraggedElements,
    textMaxLength,
}) => {
    const viewReady = !!content;
    const widgetWrapperRef = useRef(null);

    const view = useCallback(() => {
        return (
            <Text size={TextSize.Large}>
                <RawUserContent content={content} />
            </Text>
        );
    }, [content]);

    const updateContent = useCallback(
        (value) => {
            employerConstructorModifyWidget({ id, content: value });
        },
        [employerConstructorModifyWidget, id]
    );

    const checkInvalid = useCallback(() => {
        employerConstructorModifyWidget({ id, invalid: textHasError({ content }, textMaxLength) === 'INVALID_LENGTH' });
    }, [content, employerConstructorModifyWidget, id, textMaxLength]);

    const clearInvalid = useCallback(() => {
        employerConstructorModifyWidget({ id, invalid: false });
    }, [employerConstructorModifyWidget, id]);

    useEffect(() => {
        if (invalidOnSave) {
            scrollToElement(widgetWrapperRef.current, { centered: false, topOffset: 0 });
            employerConstructorModifyWidget({ id, invalidOnSave: false });
        }
    }, [employerConstructorModifyWidget, id, invalidOnSave]);

    const edit = useCallback(() => {
        return (
            <WidgetTip code={Widget.Text}>
                <WidgetControls
                    getMovedElementProps={getMovedElementProps}
                    edit={false}
                    upload={false}
                    name={trls[TextWidget.trls.widgetTextName]}
                    id={id}
                />
                <TextContentEditor
                    value={content}
                    onChange={updateContent}
                    onBlur={checkInvalid}
                    onFocus={clearInvalid}
                    overlapFrame={hasDraggedElements}
                    invalid={invalid}
                />
            </WidgetTip>
        );
    }, [
        getMovedElementProps,
        trls,
        id,
        content,
        updateContent,
        checkInvalid,
        clearInvalid,
        hasDraggedElements,
        invalid,
    ]);

    return (
        <WidgetWrapper
            dragged={dragged}
            edit={edit}
            view={view}
            editMode={editMode}
            viewReady={viewReady}
            invalid={invalid ? format(trls[TextWidget.trls.lengthError], { '{0}': textMaxLength }) : null}
            ref={widgetWrapperRef}
        />
    );
};

TextWidget.propTypes = {
    trls: PropTypes.object,
    editMode: PropTypes.bool,
    content: PropTypes.string,
    id: PropTypes.number,
    employerConstructorModifyWidget: PropTypes.func,
    getMovedElementProps: PropTypes.func,
    dragged: PropTypes.bool,
    hasDraggedElements: PropTypes.bool,
    invalid: PropTypes.bool,
    invalidOnSave: PropTypes.bool,
    textMaxLength: PropTypes.number,
};

TextWidget.trls = {
    widgetTextName: 'employer.constructor.widgetname.text',
    lengthError: 'employer.constructor.widget.text.invalid',
};

export default connect(
    ({ employerConstructorSettings }) => ({
        textMaxLength: employerConstructorSettings?.widgetSettings?.textMaxLength,
    }),
    { employerConstructorModifyWidget }
)(translation(TextWidget));
