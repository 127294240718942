import { useMemo } from 'react';

import { DataProviderResult, CellText, DataProvider } from '@hh.ru/magritte-ui';
import MetroPin from 'bloko/blocks/metroPin';

import { useSelector } from 'src/hooks/useSelector';
import { buildDataProvider, getAutosuggestItems } from 'src/utils/suggest/buildDataProvider';

import { Metro } from 'src/components/AddressSuggest/types';

const AUTOSUGGEST_URL = '/autosuggest/multiprefix/v2';

const formatter = (items: Metro[] | null): DataProviderResult<Metro> => {
    if (!items) {
        return [];
    }

    return items.map((item) => ({
        type: 'cells',
        items: [
            {
                data: item,
                value: item?.name ?? '',
                componentProps: {
                    children: (
                        <>
                            <CellText data-qa="address-edit-metro-suggest-item">
                                <MetroPin color={item.line?.color} />
                                {item.name}
                            </CellText>
                        </>
                    ),
                },
            },
        ],
    }));
};

const useMetroDataProvider = (cityId: string, onSaveFirstMetro: (data: Metro[]) => void): DataProvider<Metro> => {
    const lang = useSelector((state) => state.langs[0]);
    const dynamicUrlPart = `metro_station_${lang}_${cityId}`;

    const searchProvider = useMemo(
        () =>
            buildDataProvider(AUTOSUGGEST_URL, dynamicUrlPart, formatter, getAutosuggestItems, {
                minCharsCount: 2,
                onBlurSaveFirstValue: onSaveFirstMetro,
            }),
        [dynamicUrlPart, onSaveFirstMetro]
    );

    return searchProvider;
};

export default useMetroDataProvider;
