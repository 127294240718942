import { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import Layers from 'bloko/common/constants/layersCssClasses';

import { getCurrentAddress, EMPTY_ITEM } from 'Modules/EmployerAddresses/helpers';
import AddAddressModal from 'src/components/AddressSuggest/AddAddressModal';
import AddressBlokoSuggest from 'src/components/AddressSuggest/AddressBlokoSuggest';
import AddressItem from 'src/components/AddressSuggest/AddressItem';
import ErrorComponent from 'src/components/EmployerConstructor/ErrorComponent';
import translation from 'src/components/translation';
import useToggleState from 'src/hooks/useToggleState';
import { employerConstructorModifyWidget } from 'src/models/employerConstructor';
import fetcher from 'src/utils/fetcher';

import AddressWidgetMap from 'src/components/EmployerConstructor/widgets/AddressWidgetMap';
import WidgetControls from 'src/components/EmployerConstructor/widgets/WidgetControls';
import WidgetWrapper from 'src/components/EmployerConstructor/widgets/WidgetWrapper';

const employerAddressesActions = makeSetStoreField('employerAddresses');

const AddressWidget = ({
    trls,
    editMode,
    addresses,
    addressId,
    id,
    employerAddressesActions,
    addressesLoaded,
    setAddressesLoaded,
    employerConstructorModifyWidget,
    getMovedElementProps,
    dragged,
    addressesFetching,
    remoteSearch,
}) => {
    const [addAddressModalVisible, addAddressModalToggle] = useToggleState(false);
    const [inited, setInited] = useState(false);
    const [viewItem, setViewItem] = useState(
        getCurrentAddress(addresses, addressId, trls[AddressWidget.trls.metroStation])
    );
    const [selectedItem, setSelectedItem] = useState(viewItem);
    const viewReady = viewItem !== EMPTY_ITEM;

    const [localEditMode, toggleLocalEditMode, setLocalEditMode] = useToggleState(!viewReady);
    const [suggestInputValue, setSuggestInputValue] = useState(selectedItem.text);
    const [invalid, setInvalid] = useState(false);

    const setSelectedItemWrapper = useCallback(
        (address) => {
            setSuggestInputValue(address.text);
            setSelectedItem(address);
            if (address !== EMPTY_ITEM) {
                if (!addresses.some((item) => item.id === address.source.id)) {
                    employerAddressesActions([...addresses, address.source]);
                }
                setInvalid(false);
            }
        },
        [addresses, employerAddressesActions]
    );

    const setSuggestInputValueWrapper = useCallback(
        (inputValue) => {
            const value = inputValue.trim();
            setSuggestInputValue(value);

            if (!value) {
                setSelectedItemWrapper(EMPTY_ITEM);
            }
            if (value !== selectedItem.text) {
                setSelectedItem(EMPTY_ITEM);
            }
        },
        [selectedItem.text, setSelectedItemWrapper]
    );

    useEffect(() => {
        if (editMode && localEditMode && !addressesLoaded && !addressesFetching.current) {
            if (!remoteSearch) {
                addressesFetching.current = true;
                fetcher
                    .get('/employer/addresses/ajax')
                    .then((data) => {
                        employerAddressesActions(data.items || []);
                        setAddressesLoaded(true);
                        addressesFetching.current = false;
                        return null;
                    })
                    .catch(console.error);
            } else {
                setAddressesLoaded(true);
            }
        }
    }, [
        addressesFetching,
        addressesLoaded,
        editMode,
        employerAddressesActions,
        localEditMode,
        remoteSearch,
        setAddressesLoaded,
    ]);

    useEffect(() => {
        if (!inited && addressesLoaded) {
            setInited(true);
            const currentAddress = getCurrentAddress(addresses, addressId, trls[AddressWidget.trls.metroStation]);
            setSelectedItemWrapper(currentAddress);
            setViewItem(currentAddress);
            if (currentAddress === EMPTY_ITEM) {
                setLocalEditMode(true);
            }
        }
    }, [addressId, addresses, addressesLoaded, inited, setLocalEditMode, setSelectedItemWrapper, trls]);

    const cancelEdit = useCallback(() => {
        setSelectedItemWrapper(getCurrentAddress(addresses, addressId, trls[AddressWidget.trls.metroStation]));
        setInvalid(false);
        setLocalEditMode(false);
    }, [addressId, addresses, setLocalEditMode, setSelectedItemWrapper, trls]);

    const saveAddressId = useCallback(() => {
        if (selectedItem === EMPTY_ITEM) {
            setInvalid(true);
            return;
        }
        setViewItem(selectedItem);
        employerConstructorModifyWidget({
            id,
            addressId: selectedItem.id,
        });
        setLocalEditMode(false);
    }, [employerConstructorModifyWidget, id, selectedItem, setLocalEditMode]);

    const view = useCallback(() => {
        const currentAddress = addresses.find((address) => address.id === addressId);
        return (
            <div className="widget-address-view">
                <AddressWidgetMap address={currentAddress} />
                <Gap top left right bottom>
                    <Text size={TextSize.Large}>
                        <AddressItem {...viewItem} />
                    </Text>
                </Gap>
            </div>
        );
    }, [addressId, addresses, viewItem]);

    const edit = useCallback(() => {
        return (
            <>
                <WidgetControls
                    upload={false}
                    edit={!localEditMode}
                    getMovedElementProps={getMovedElementProps}
                    editText={trls[AddressWidget.trls.widgetMapEditText]}
                    onEdit={toggleLocalEditMode}
                    name={trls[AddressWidget.trls.widgetMapName]}
                    id={id}
                />
                {localEditMode && (
                    <div className="widget-edit-description">
                        <VSpacing base={2} />
                        <div>{trls[AddressWidget.trls.description]}</div>
                        <div>
                            <VSpacing base={2} />
                            {addressesLoaded && (
                                <AddressBlokoSuggest
                                    addresses={addresses}
                                    remoteSearch={remoteSearch}
                                    address={selectedItem}
                                    inputValue={suggestInputValue}
                                    onSelect={setSelectedItemWrapper}
                                    placeholder={trls[AddressWidget.trls.search]}
                                    invalid={invalid}
                                    setInvalid={setInvalid}
                                    searchOnFocus={true}
                                    selectOnBlur={false}
                                    limit={10}
                                    layer={Layers.Topmost}
                                    inputProps={{
                                        onChange: setSuggestInputValueWrapper,
                                    }}
                                />
                            )}
                            <ErrorComponent show={invalid}>{trls[AddressWidget.trls.invalidMessage]}</ErrorComponent>

                            <VSpacing base={2} />
                            <Button onClick={saveAddressId} kind={ButtonKind.Success}>
                                {!viewReady
                                    ? trls[AddressWidget.trls.uploadButton]
                                    : trls[AddressWidget.trls.saveAddress]}
                            </Button>
                            {viewReady && (
                                <FormSpacer>
                                    <Button onClick={cancelEdit}>{trls[AddressWidget.trls.cancelEdit]}</Button>
                                </FormSpacer>
                            )}
                            <Gap top>
                                <Link appearance={LinkAppearance.Pseudo} onClick={addAddressModalToggle}>
                                    {trls[AddressWidget.trls.addAddress]}
                                </Link>
                            </Gap>
                        </div>
                        <AddAddressModal
                            onCreate={(rawAddress) => {
                                const newAddresses = [...addresses, rawAddress];
                                const currentAddress = getCurrentAddress(
                                    newAddresses,
                                    rawAddress.id,
                                    trls[AddressWidget.trls.metroStation]
                                );
                                setSelectedItemWrapper(currentAddress);
                                employerAddressesActions(newAddresses);
                            }}
                            onSelect={(address) => {
                                const currentAddress = getCurrentAddress(
                                    addresses,
                                    address.id,
                                    trls[AddressWidget.trls.metroStation]
                                );
                                setSelectedItemWrapper(currentAddress);
                            }}
                            onClose={addAddressModalToggle}
                            visible={addAddressModalVisible}
                        />
                    </div>
                )}
                {!localEditMode && viewReady && view()}
            </>
        );
    }, [
        addAddressModalToggle,
        addAddressModalVisible,
        addresses,
        addressesLoaded,
        cancelEdit,
        employerAddressesActions,
        getMovedElementProps,
        id,
        invalid,
        localEditMode,
        remoteSearch,
        saveAddressId,
        selectedItem,
        setSelectedItemWrapper,
        setSuggestInputValueWrapper,
        suggestInputValue,
        toggleLocalEditMode,
        trls,
        view,
        viewReady,
    ]);

    return <WidgetWrapper dragged={dragged} edit={edit} view={view} editMode={editMode} viewReady={viewReady} />;
};

AddressWidget.propTypes = {
    trls: PropTypes.object,
    editMode: PropTypes.bool,
    id: PropTypes.number,
    addresses: PropTypes.arrayOf(PropTypes.object),
    addressId: PropTypes.number,
    addressesLoaded: PropTypes.bool,
    employerConstructorModifyWidget: PropTypes.func,
    setAddressesLoaded: PropTypes.func,
    employerAddressesActions: PropTypes.func,
    getMovedElementProps: PropTypes.func,
    dragged: PropTypes.bool,
    addressesFetching: PropTypes.shape({ current: PropTypes.bool }).isRequired,
    remoteSearch: PropTypes.bool,
};

AddressWidget.trls = {
    widgetMapName: 'employer.constructor.widgetname.map',
    widgetMapEditText: 'employer.constructor.widgetedit.map',
    description: 'employer.constructor.addresswidget.description',
    uploadButton: 'employer.constructor.addresswidget.uploadButton',
    saveAddress: 'employer.constructor.addresswidget.saveAddress',
    cancelEdit: 'employer.constructor.addresswidget.cancelEdit',
    search: 'employer.constructor.addresswidget.inputPlaceholder',
    metroStation: 'metrostation',
    invalidMessage: 'employer.constructor.addresswidget.invalid',
    addAddress: 'employer.constructor.addresswidget.addaddress',
};

export default connect(
    ({ employerAddresses, addressesSuggestRemoteMode }) => ({
        addresses: employerAddresses,
        remoteSearch: addressesSuggestRemoteMode,
    }),
    { employerAddressesActions, employerConstructorModifyWidget }
)(translation(AddressWidget));
