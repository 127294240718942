import memoize from 'memoize-one';

import { AddressFromYandexData } from 'Modules/Maps/Utils';
import { EmployerAddress, MetroStation } from 'src/models/employerAddresses.types';

export const EMPTY_ITEM = { text: '', id: undefined };

export interface EmployerAddressShort
    extends Pick<EmployerAddress, 'id' | 'displayName' | 'mapData' | 'description' | 'city' | 'street' | 'building'> {
    metroStations?: EmployerAddress['metroStations'];
}

export interface PreparedItem {
    id: number | string;
    text: string;
    displayName: string;
    description: string;
    source: EmployerAddressShort;
    metroStations: MetroStation[];
}
export type PreparedAddress = PreparedItem | typeof EMPTY_ITEM;

export const isNonEmptyAddress = (address: PreparedAddress): address is PreparedItem => {
    return 'id' in address && address.id !== undefined;
};

export const placeholderTemplate = ({
    text,
    metroStations,
    metroStationTrl,
}: {
    text: string;
    metroStations: MetroStation[];
    metroStationTrl: string;
}): string => {
    const separatorWithTrl = `, ${metroStationTrl}`;
    const metroStationsText =
        metroStations && metroStations.length !== 0
            ? `${separatorWithTrl}${metroStations.map((station) => station.name).join(separatorWithTrl)}`
            : '';

    return `${text}${metroStationsText}`;
};

export const prepareAddress = (address: EmployerAddressShort, metroStationTrl: string): PreparedItem => {
    const metroStations = address.metroStations?.metro || [];
    return {
        id: address.id,
        text: placeholderTemplate({ text: address.displayName, metroStations, metroStationTrl }),
        displayName: address.displayName,
        metroStations,
        description: address.description || '',
        source: address,
    };
};

export const prepareAddresses = (
    addresses: EmployerAddressShort[],
    metroStationTrl: string,
    addressIdNotToShow?: number
): PreparedItem[] => {
    if (addressIdNotToShow) {
        return addresses
            .filter((address) => address.id !== addressIdNotToShow)
            .map((address) => prepareAddress(address, metroStationTrl));
    }
    return addresses.map((address) => prepareAddress(address, metroStationTrl));
};

export const getCurrentAddress = memoize(
    (employerAddresses: EmployerAddressShort[], id: number | undefined, metroStationTrl: string): PreparedAddress => {
        const currentAddress = employerAddresses.find((address) => address.id === Number(id));
        if (currentAddress) {
            return prepareAddress(currentAddress, metroStationTrl);
        }
        return EMPTY_ITEM;
    }
);

export interface RawAddress {
    id: string;
    fullAddress: string;
    metroStations: MetroStation[];
    description: string;
    mapData: string;
    city: string;
    street: string;
    building: string;
}

export const prepareNewAddress = (addressObj: AddressFromYandexData): EmployerAddressShort => {
    return {
        id: Number(addressObj.id),
        displayName: addressObj.fullAddress,
        metroStations:
            addressObj?.metroStations.length > 0
                ? {
                      metro: addressObj.metroStations,
                  }
                : undefined,
        description: addressObj.description,
        mapData: addressObj.mapData,
        city: addressObj.city,
        street: addressObj.street,
        building: addressObj.building,
    } as EmployerAddressShort;
};
